import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {NbButtonModule, NbIconModule, NbTooltipModule} from '@nebular/theme';

@Component({
  selector: 'mkad-alert-inline',
  standalone: true,
  imports: [
    NbIconModule,
    NbButtonModule,
    NbTooltipModule,
  ],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="alert_inline_content border-round-lg flex align-items-center gap-3 w-full relative">
      <section>
        <span class="alert_inline_initials grid__center uppercase">{{ initials }}</span>
      </section>
      <section class="flex flex-column mr-auto">
        <span class="text-base font-bold">{{ name }}</span>
        <small>{{ label }}</small>
      </section>
      @if (showRightButton) {
        <button nbButton outline class="border-round-lg" size="small" status="primary" (click)="clickRight.emit()"
                [nbTooltipDisabled]="!tooltipRightButton" [nbTooltip]="tooltipRightButton" tabindex="1">
          <nb-icon icon="plus" class="grid__center"/>
        </button>
      }
    </div>
  `,
})
export class AlertInlineComponent {
  @Input({required: true}) name = '';
  @Input({required: false}) label = '';
  @Input({required: false}) showRightButton = false;
  @Input({required: false}) tooltipRightButton: string = '';
  @Output() clickRight = new EventEmitter();


  /**
   * @description
   * Method that obtains initials
   */
  get initials() {
    const name = this.name;
    const initials: string[] = name.split(' ');
    return initials[0][0] + '' + (initials[1] ? initials[1][0] : '');
  }
}
