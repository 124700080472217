import {Component, Input} from '@angular/core';
import {NbIconModule, NbTooltipModule} from '@nebular/theme';

@Component({
  selector: 'mkad-form-label',
  template: `
    <label [for]="controlId">
      {{ text }}
      @if (isRequired) {
        <sup class="required ml-1">
          <nb-icon class="text-base" icon="circle-exclamation" [nbTooltip]="tooltip ?? ''" [status]="status" />
        </sup>
      }
    </label>
  `,
  standalone: true,
  imports: [NbIconModule, NbTooltipModule],
})
export class MkadFormLabelComponent {
  @Input({required: true}) controlId?: string;
  @Input({required: true}) text?: string;
  @Input({required: false}) tooltip?: string;
  @Input({required: false}) status?: string;
  @Input({required: false}) isRequired: boolean = true;
}
