{
  "name": "meerkad",
  "version": "2024.2.0-alpha.10-dev.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -o",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@asymmetrik/ngx-leaflet": "^17.0.0",
    "@nebular/auth": "^13.0.0",
    "@nebular/date-fns": "^13.0.0",
    "@nebular/eva-icons": "13.0.0",
    "@nebular/moment": "^13.0.0",
    "@nebular/security": "^13.0.0",
    "@nebular/theme": "^13.0.0",
    "@ngx-translate/core": "15.0.0",
    "@ngx-translate/http-loader": "8.0.0",
    "@swimlane/ngx-datatable": "^20.1.0",
    "@zibanu/auth": "file:zibanu/zibanu-auth-0.0.1-alpha.6-dev.30.tgz",
    "@zibanu/lib": "file:zibanu/zibanu-lib-0.0.1-alpha.8-dev.22.tgz",
    "@zibanu/ui": "file:zibanu/zibanu-ui-0.0.1-alpha.9-dev.21.tgz",
    "date-fns": "2.0.0",
    "echarts": "5.5.0",
    "eva-icons": "^1.1.3",
    "leaflet": "^1.9.4",
    "ngx-echarts": "17.1.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.0",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.1.0",
    "@angular/compiler-cli": "^17.1.0",
    "@types/jasmine": "~5.1.0",
    "@types/leaflet": "^1.9.8",
    "@typescript-eslint/eslint-plugin": "6.19.0",
    "@typescript-eslint/parser": "6.19.0",
    "eslint": "^8.56.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.3.2"
  }
}
